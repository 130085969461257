<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-dropdown @command="onSelectGroup">
                <el-button type="success" style="width:144px;">
                    {{curGroupLabel}}<i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="item in serverGroups" :key="item.id" :command="item" icon="el-icon-coin">
                        {{item.name}}
                    </el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
            <el-select v-model="curNType" style="width:144px; margin-left: 8px;" @change="onSelectNtype">
                <el-option v-for="item in ntypes" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd" style="margin-left: 8px;">发送新邮件</el-button>
            <el-button type="primary" icon="el-icon-search" @click="onClickQueryMail" style="margin-left: 8px;">搜索邮件</el-button>
        </el-col>
        <el-col :span="24">
            <el-table ref="multipleTable" :data="mailList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id" width="60"> </el-table-column>
                <el-table-column prop="groupname" label="区服列表" show-overflow-tooltip></el-table-column>
                <el-table-column prop="servername" label="区服" show-overflow-tooltip></el-table-column>
                <el-table-column prop="playerid" label="角色id" show-overflow-tooltip></el-table-column>
                <el-table-column prop="title" label="邮件标题" show-overflow-tooltip></el-table-column>
                <el-table-column prop="content" label="邮件正文" show-overflow-tooltip></el-table-column>
                <el-table-column prop="items" label="物品" show-overflow-tooltip></el-table-column>
                <el-table-column prop="send_time" label="发送时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="expire_time" label="过期时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="审批状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.examining===0" style="color: #0000ff;">待审批</span>                        
                        <span v-if="scope.row.examining===1" style="color: #00C957;">通过</span>
                        <span v-if="scope.row.examining===2" style="color: #FF0000;">拒绝</span>
                    </template>
                </el-table-column>
                <el-table-column label="发送状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state===0">待发</span>
                        <span v-if="scope.row.state===1">提交</span>
                        <span v-if="scope.row.state===2">成功</span>
                        <span v-if="scope.row.state===3" style="color: #FF0000;">失败</span>
                        <span v-if="scope.row.state===4" style="color: #FF0000;">撤回</span>
                    </template>
                </el-table-column>                
                <el-table-column prop="create_time" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="操作" width="200">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onDeprecatedMail(scope.row)" type="danger"
                            icon="el-icon-delete" size="small" :disabled="2!=scope.row.state">撤回</el-button>
                        <el-button @click.native.prevent="onDetailMail(scope.row)" type="primary"
                            icon="el-icon-s-custom" size="small" v-if="showBtnExamining(scope.row)">审批</el-button>
                        <el-button @click.native.prevent="onDetailMail(scope.row)" type="success"
                            icon="el-icon-more" size="small"  v-if="showBtnDetail(scope.row)">详情</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              background
              :page-size="20"
              :total="total">
            </el-pagination>            
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog title="发送新邮件" :visible.sync="dialogForm" width="800px" :close-on-click-modal="false" center
            class="formparams">
            <el-form :model="form">
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-select v-model="form.group" placeholder="选择区服列表" value-key="id" @change="onFormSelectGroup" style="width: 100%;">
                        <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="区服" :label-width="formLabelWidth">
                    <el-select
                    v-model="form.serverids"
                    :multiple="true"
                    placeholder="不指定则是列表里全部区服" style="width: 100%;">
                        <el-option v-for="item in formServers" :key="item.id" :label="item.title" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="玩家id" :label-width="formLabelWidth">
                    <el-input v-model="form.playerid" @change="onPlayerIdChanged" placeholder="不指定则是全服,多个ID用逗号分隔,指定ID会忽略区服"></el-input>
                    <el-button type="primary" size="mini" icon="el-icon-search" @click="onQueryPlayerId" :disabled="form.playeridDisQuery">查询</el-button>
                </el-form-item>
                <el-form-item label="邮件标题" :label-width="formLabelWidth">
                    <el-input v-model="form.title"></el-input>
                </el-form-item>
                <el-form-item label="邮件内容" :label-width="formLabelWidth">
                    <el-input v-model="form.content" type="textarea" :rows="3"></el-input>
                </el-form-item>
                <el-form-item label="选择配置" :label-width="formLabelWidth">
                    <el-select v-model="form.cfgver" @change="onSelectPkg" placeholder="请选择配置文件" style="width:100%;">
                        <el-option v-for="item in pkgList" :key="item.id" :label="item.name" :value="item.id">
                            <i class="el-icon-folder"></i>
                            <span>{{item.name}}</span>
                            <span style="float:right; color: #8492a6;">{{item.tag}}</span>
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="选择物品" :label-width="formLabelWidth">
                    <el-button type="primary" v-for="node in itemConfigs" :key="node.type" @click="onSelectItem(node)">
                        {{node.label}}
                    </el-button>
                </el-form-item>
                <el-form-item label="邮件发出时间" :label-width="formLabelWidth">
                    <el-date-picker v-model="form.send_time" type="datetime" placeholder="选择日期时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="邮件过期时间" :label-width="formLabelWidth">
                    <el-date-picker v-model="form.expire_time" type="datetime" placeholder="选择日期时间" style="width: 100%;">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddMail">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========物品弹框====== -->
        <el-dialog :title="titleItemForm" :visible.sync="dialogItemForm" width="80%" :close-on-click-modal="false"
            :show-close="false" center class="itemdialog">
            <div style="width: 100%">
                <el-row>
                    <el-col :span="11">
                        <el-input v-model="search" placeholder="关键字搜索" style="width:100%;"></el-input>
                    </el-col>
                    <el-col :span="2">
                        <el-button type="success" plain style="width:100%;" disabled>&gt;&gt;&gt;&gt;&gt;&gt;
                        </el-button>
                    </el-col>
                    <el-col :span="11">
                        <el-button type="primary" style="width:100%;" disabled>已选择的项</el-button>
                    </el-col>
                </el-row>
                <el-row :gutter="0">
                    <el-col :span="11">
                        <el-table :data="filterItems" border style="width: 100%" height="420" class="serverlist">
                            <el-table-column label="ID" prop="id" width="100" show-overflow-tooltip></el-table-column>
                            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="品质/等级" prop="lev" show-overflow-tooltip></el-table-column>
                            <el-table-column label="标签" prop="tag" show-overflow-tooltip></el-table-column>
                            <el-table-column label="描述" prop="desc" show-overflow-tooltip></el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" type="primary" @click="onAddItem(scope.row)">添加</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                    <el-col :span="2">
                        <el-divider direction="vertical"></el-divider>
                    </el-col>
                    <el-col :span="11">
                        <el-table :data="mailItems" border style="width: 100%" height="420" class="serverlist">
                            <el-table-column label="ID" prop="id" width="100" show-overflow-tooltip></el-table-column>
                            <el-table-column label="名称" prop="name" show-overflow-tooltip></el-table-column>
                            <el-table-column label="品质/等级" prop="lev" show-overflow-tooltip></el-table-column>
                            <el-table-column label="标签" prop="tag" show-overflow-tooltip></el-table-column>
                            <el-table-column label="自定义参数" width="100">
                                <template slot-scope="scope">
                                    <el-input v-model="scope.row.param" size="mini"></el-input>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" width="220">
                                <template slot-scope="scope">
                                    <el-input-number v-model="scope.row.amount" size="mini" :min="1"></el-input-number>
                                    <el-button size="mini" type="danger" @click="onRemoveItem(scope.row)"
                                        style="float:right;">移除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="dialogItemForm=false">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========邮件搜索弹框BEGIN====== -->
        <el-dialog title="邮件搜索" :visible.sync="dialogSearchFrom" width="30%" center class="formparams"
            :close-on-click-modal="false">
            <el-form :model="form">
                <el-form-item label="邮件标题">
                    <el-input v-model="searchTitle" placeholder="请输入邮件标题关键字"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onQueryMail">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========邮件搜索弹框END====== -->
        <!-- ========玩家查询弹框BEGIN====== -->
        <el-dialog title="玩家id查询检查" :visible.sync="dialogQueryPlayer" width="900px" center class="formparams"
            :close-on-click-modal="false">
            <el-table ref="multipleTable" :data="roleList" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column v-for="item in labelArr" :key="item.key" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
        <!-- ========玩家查询弹框END====== -->
		<!-- ========邮件详情弹框BEGIN====== -->
		<el-dialog title="邮件详情" :visible.sync="dialogDetailMail" width="800px" :close-on-click-modal="false" center class="formparams">
			<el-form size=small :model="detailForm">  
                <el-form-item label="区服列表" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.groupname"></el-input>
                </el-form-item> 
                <el-form-item label="区服" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.servername"></el-input>
                </el-form-item> 
                <el-form-item label="角色id" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.playerid"></el-input>
                </el-form-item>                 
                <el-form-item label="邮件标题" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.title"></el-input>
                </el-form-item>                 
                <el-form-item label="邮件正文" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.content"></el-input>
                </el-form-item>
                <el-form-item label="物品表" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.items"></el-input>
                </el-form-item>                
                <el-form-item label="发送时间" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.send_time"></el-input>
                </el-form-item>   
                <el-form-item label="过期时间" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.expire_time"></el-input>
                </el-form-item>                   
                <el-form-item label="创建时间" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.create_time"></el-input>
                </el-form-item>                                
                <el-form-item label="发送状态" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.stateTxt"></el-input>
                </el-form-item>                                
                <el-form-item label="审批状态" :label-width="formLabelWidth">
                    <el-input v-model="detailForm.examiningTxt"></el-input>
                </el-form-item>
            </el-form>
			<div slot="footer" class="dialog-footer">
				<el-button v-if="0==detailForm.examining&&examiningMail" size=small type="success" icon="el-icon-circle-check" @click="onAgreeMail">通过</el-button>
				<el-button v-if="0==detailForm.examining&&examiningMail" size=small type="danger" icon="el-icon-remove-outline" @click="onRefuseMail">拒绝</el-button>
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'
    import tool from '../../tool.js'
    import axios from 'axios'

    export default {
        data() {
            return {
                pkgCenterApi: null,
                labelArr: [],
                fields: [],
                roleList: [],
                dialogQueryPlayer: false,
                /////////////////
                dialogDetailMail: false,
                detailForm : {
                },
                curNType: -1,
                ntypes: [{id:-1,name:'全部状态'},{id:0,name:'待审批'},{id:1,name:'审批通过'},{id:2,name:'审批拒绝'}],
                /////////////////
                mailList: [],
                dialogForm: false,
                dialogItemForm: false,
                titleItemForm: '',
                form: {
                    id: '',
                    group: '',
                    serverids: [],
                    playerid: '',
                    playeridDisQuery: true,
                    title: '',
                    content: '',
                    send_time: '',
                    expire_time: '',
                    cfgver: '',
                },
                formServers: [],
                search: '',
                pkgList: [], // 可选配置
                cfgItems: [], // 可选物品
                mailItems: [], // 选择的物品
                itemConfigs: [], // 邮件物品配置                
                formLabelWidth: '130px',
                serverGroups: [],
                curGroupId: -1, // 当前选择分组(默认全部)
                curGroupLabel: "全部区服列表", // 当前选择分组名
                searchTitle: '', // 邮件搜索标题关键字
                dialogSearchFrom: false,
                curPage: 1,
                total: 0,
                tableHeight: document.documentElement.clientHeight-136-32,
            }
        },
        created: function() {
            // 打包中心httl
            this.pkgCenterApi = axios.create({
                baseURL: this.$store.state.game.pkgurl,
                timeout: 15000
            });  
            this.itemConfigs = JSON.parse(JSON.stringify(this.$store.state.game.mailItems));
            for (let i = 0; i < this.itemConfigs.length; i++) {
                this.itemConfigs[i].items = [];
                this.itemConfigs[i].selected = [];
            }
            this.refreshMailList()
            this.refreshGroupList()
            this.refreshPkgList()
            // 玩家查询表头初始化
            this.labelArr = [{prop:'channel',label:'渠道号'}, {prop:'username',label:'用户名'}, {prop:'serverinfo',label:'所在区服'}, {prop:'cid',label:'角色id'}];
            let fieldarr = this.$store.state.game.playerinfo.labelArr;
            for (let i=0; i<fieldarr.length; i++) {
                this.labelArr.push( fieldarr[i] );
                this.fields.push( fieldarr[i].prop );
            }
            let str = "11,22";
            console.log("SSS",Number(str.split(',')[0]));
        },
        computed: {
            ...mapGetters(
                ['gameHttp','ownOpPermission']
            ),
            // 是否有邮件审批权限
            examiningMail: function() {
                return this.ownOpPermission("mail");
            },
            // 物品关键词过滤显示
            filterItems() {
                return this.cfgItems.filter((data) => {
                    data.amount = 1; // 物品默认数量1
                    data.param = ""; // 自定义参数默认空串
                    // 已选择过的则不用再显示了
                    for (let i = 0; i < this.mailItems.length; i++) {
                        if (data.id == this.mailItems[i].id) {
                            return false;
                        }
                    }
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.search || (data.id.includes(this.search) || data.name.includes(this.search) ||
                        data.desc.includes(this.search) || data.tag.includes(this.search));
                })
            },
        },
        methods: {
            // 发送请求到打包中心
            pkgCenterHttp (cmd, data) {
                let rqs = JSON.parse(JSON.stringify(data));
                rqs.cmd = cmd;
                return this.pkgCenterApi({
                    method: 'post',
                    url: '/sygame',
                    data: rqs
                });
            },
            // 刷新列表
            refreshMailList() {
                this.gameHttp(3011, {
                    examining: this.curNType,
                    groupid: this.curGroupId,
                    page:this.curPage
                }).then((response) => {
                    this.total = response.data.total;
                    this.mailList = response.data.list
                });
            },
            // 分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.refreshMailList();
            },
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 选择邮件状态查看
            onSelectNtype() {
                this.curPage = 1;
                this.refreshMailList()
            },
            // 显示审批按钮
            showBtnExamining(row) {
                return (this.examiningMail && 0==row.examining);
            },
            // 显示详情按钮
            showBtnDetail(row) {
                return (!this.examiningMail || 0!=row.examining);
            },
            // 查看邮件详情
            onDetailMail(row) {
                this.dialogDetailMail = true;
                this.detailForm = JSON.parse( JSON.stringify(row) );
                this.detailForm.stateTxt = this.mailStateTxt(row.state);
                if      (0 == row.examining) this.detailForm.examiningTxt = "待审批";
                else if (1 == row.examining) this.detailForm.examiningTxt = "通过";
                else if (2 == row.examining) this.detailForm.examiningTxt = "拒绝";
            },
            // 审批邮件-通过
            onAgreeMail() {
                this.$confirm('审批通过邮件[ ' + this.detailForm.title + ' ]?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let mail = {id:this.detailForm.id, examine:1};
                    this.gameHttp(3012, mail).then((response) => {
                        this.dialogDetailMail = false;
                        if (0 == response.data.errcode) {
                            this.refreshMailList()
                            this.$message.success("审批通过已提交");
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    });
                }).catch(() => {})
            },
            // 审批邮件-拒绝
            onRefuseMail() {
                this.$confirm('审批拒绝邮件[ ' + this.detailForm.title + ' ]?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let mail = {id:this.detailForm.id, examine:2};
                    this.gameHttp(3012, mail).then((response) => {
                        this.dialogDetailMail = false;
                        if (0 == response.data.errcode) {
                            this.refreshMailList()
                            this.$message.success("审批拒绝已提交");
                        } else {
                            this.$message.error(response.data.errmsg)
                        }
                    });
                }).catch(() => {})               
            },
            // 邮件状态
            mailStateTxt(v) {
                if      (0 == v) return "待发";
                else if (1 == v) return "提交";
                else if (2 == v) return "成功";
                else if (3 == v) return "失败";
                else if (4 == v) return "撤回";
            },
            // 弹出新增参数编辑框
            onClickAdd() {
                this.dialogForm = true
                this.formServers = []
                for (let k in this.form) {
                    this.form[k] = ''
                }
                this.form.serverids = [];
                this.form.playeridDisQuery = true;
                // 清空物品选择
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    this.itemConfigs[i].items = [];
                    this.itemConfigs[i].selected = [];
                }
                // 玩家查询清空
                this.roleList = []
            },
            // 表单选择分组
            onFormSelectGroup(item) {
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.formServers = response.data.list
                })
            },
            // 配置包列表
            refreshPkgList() {
                this.pkgCenterHttp(1007, {
                    type: 'config'
                }).then((response) => {
                    this.pkgList = response.data.list
                })
            },
            // 选择指定pkg里的配置
            onSelectPkg(id) {
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    let node = this.itemConfigs[i]
                    this.loadLuaItems(id, node);
                }
            },
            loadLuaItems(id, node) {
                this.pkgCenterHttp(3122, {
                    cfgver: id,
                    luafile: node.lua,
                    fields: node.fields,
                    load: node.load,
                    dbtable: node.dbtbl,
                }).then((response) => {
                    node.items = response.data.list;
                })
            },
            // 选择物品类型
            onSelectItem(item) {
                this.cfgItems = item.items;
                this.mailItems = item.selected;
                this.dialogItemForm = true
                this.titleItemForm = item.label + '选择';
            },
            // 添加物品到选择列
            onAddItem(row) {
                let item = JSON.parse(JSON.stringify(row))
                this.mailItems.push(item)
            },
            // 移除选择的物品
            onRemoveItem(row) {
                for (let i = 0; i < this.mailItems.length; i++) {
                    if (row.id == this.mailItems[i].id) {
                        this.mailItems.splice(i, 1);
                        break;
                    }
                }
            },
            // 添加新邮件
            onAddMail() {
                if (!this.form.group) {
                    this.$message.error('请选择分组');
                    return;
                }
                if (!this.form.title) {
                    this.$message.error('邮件标题不能为空');
                    return;
                }
                if (!this.form.content) {
                    this.$message.error('邮件内容不能为空');
                    return;
                }
                if (!this.form.send_time) {
                    this.$message.error('邮件发出时间未设置');
                    return;
                }
                if (!this.form.expire_time) {
                    this.$message.error('邮件过期时间未设置');
                    return;
                }
                let playerids = [];
                let arr = this.form.playerid.trim().split(',');
                for (let i=0; i<arr.length; i++) {
                    if (!arr[i]) {continue;}
                    if (!arr[i].trim()) {
                        this.$message.error('不能指定空的玩家id');
                        return;                        
                    }
                    playerids.push( Number(arr[i].trim()) );
                }
                this.dialogForm = false
                // 提取参数
                let mail = {
                    groupid: this.form.group.id,
                    serverids: this.form.serverids,
                    playerids: playerids,
                    title: this.form.title,
                    content: this.form.content,
                    items: [],
                    send_time: tool.dateFormat(this.form.send_time),
                    expire_time: tool.dateFormat(this.form.expire_time),
                }
                // 物品信息提取
                for (let i = 0; i < this.itemConfigs.length; i++) {
                    let node = this.itemConfigs[i];
                    for (let j = 0; j < node.selected.length; j++) {
                        mail.items.push({
                            id: node.selected[j].id,
                            count: node.selected[j].amount,
                            param: node.selected[j].param.trim(),
                            type: node.type,
                        });
                    }
                }
                // 请求操作
                this.gameHttp(3012, mail).then((response) => {
                    if (0 == response.data.errcode) {
                        this.refreshMailList()
                        this.$message.success(response.data.errmsg)
                    } else {
                        this.$message.error(response.data.errmsg)
                    }
                })
            },
            // 撤回Mail
            onDeprecatedMail(row) {
                this.$confirm('是否撤回邮件[ ' + row.title + ' ]?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.gameHttp(3013, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("撤回提交成功")
                            this.refreshMailList()
                        }
                    })
                }).catch(() => {})
            },
            // 搜索邮件
            onClickQueryMail() {
                this.searchTitle = '';
                this.dialogSearchFrom = true;
            },
            onQueryMail() {
                if (!this.searchTitle) {
                    this.$message.warning("请输入邮件标题关键字");
                    return;
                }
                this.gameHttp(3011, {
                    groupid: this.curGroupId,
                    title: this.searchTitle
                }).then((response) => {
                    this.dialogSearchFrom = false;
                    if (0 == response.data.errcode) {
                        this.mailList = response.data.list
                    }
                })
            },
            // 选择服务器分组
            onSelectGroup(item) {
                this.curPage = 1;
                this.curGroupLabel = item.name
                this.curGroupId = item.id
                // 刷新选择结果
                this.refreshMailList()
            },
            // 玩家id输入变化
            onPlayerIdChanged() {
                let ids = this.form.playerid.trim();
                this.form.playeridDisQuery = (0 == ids.length);
                if (ids.length > 0) { // 指定id后，忽略区服选择
                    this.form.group = this.serverGroups[0];
                    this.form.serverids = [];
                }
            },
            onQueryPlayerId() {
                let playerids = [];
                let arr = this.form.playerid.trim().split(',');
                for (let i=0; i<arr.length; i++) {
                    if (!arr[i].trim()) {
                        this.$message.error('存在空的玩家id');
                        return;                        
                    }
                    playerids.push(arr[i].trim());
                }
                this.roleList = [];
                this.dialogQueryPlayer = true;                
                for (let i=0; i<playerids.length; i++) {
                    let rqs = {
                        cid: playerids[i],
                        player: this.$store.state.game.playerinfo.player,
                        tables: this.$store.state.game.playerinfo.tables,
                    };
                    this.gameHttp(3041, rqs).then((response) => {
                        if (0 == response.data.errcode) {
                            this.roleList.push(response.data.list[0]);
                        } else {
                            this.roleList.push({channel:"查无",username:"查无",serverinfo:"查无",cid:arr[i]});
                        }
                    });
                }
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                row,rowIndex
            }) {
                if (this.dialogQueryPlayer && "查无"==row.channel) {
                    return 'error-row';
                }
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .error-row {
        color: #000000;
        background: #ff557f;
    }    
    
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
</style>
